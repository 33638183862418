import axios, { AxiosError, AxiosResponse } from 'axios';
import { getAccessTokenCookie, clearUserCookie, clearAccessTokenCookie } from '../utils/cookie';

type HTTPTypeResolve = (val: AxiosResponse) => void;
type HTTPTypeReject = (err: AxiosError | unknown) => void;

const BASE_URL = process.env.REACT_APP_API_URL;
const MEDIA_UPLOAD_API_URL = process.env.REACT_APP_MEDIA_UPLOAD_API_URL;
const VIDEO_STORE_API_URL = process.env.REACT_APP_VIDEO_API_URL;
const EMBED_API_URL = process.env.REACT_APP_EMBED_API_URL;
const getCoreHeader = () => ({
  Accept: 'application/json',
  Authorization: `Bearer ${getAccessTokenCookie()}`,
});

const handleError = (err) => {
  if (!err || !err.response) {
    return null;
  }

  switch (err.response.status) {
    case 401:
      handleAuthError();
      return '401 Not Authorized';
    case 404:
      return '404 Not found';
    default:
      return 'Internal server error';
  }
};

const handleAuthError = (): void => {
  clearUserCookie();
  clearAccessTokenCookie();
  window.location.reload();
};

const getHandler = async (
  {
    path,
    id,
    params,
    headers,
  }: {
    path: string,
    id?: string,
    params?: any,
    headers?: any,
  },
): Promise<any> => {
  let url: string = path;
  if (id && id !== '') {
    url += `/${id}`;
  }

  return new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
    try {
      const resp: AxiosResponse = await axios.request({
        method: 'GET',
        baseURL: BASE_URL,
        url,
        params,
        headers: {
          ...getCoreHeader(),
          ...headers,
        },
      });
      resolve(resp);
    } catch (err) {
      handleError(err);
      reject(err);
    }
  });
};

const postHandler = (
  {
    path,
    body = null,
    headers,
  }: {
    path: string,
    body: any
    headers?: any,
  },
): Promise<any> => (
  new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
    try {
      const resp: AxiosResponse = await axios.request({
        method: 'POST',
        baseURL: BASE_URL,
        url: path,
        data: body,
        headers: {
          ...getCoreHeader(),
          ...headers,
        },
      });
      resolve(resp);
    } catch (err) {
      handleError(err);
      reject(err);
    }
  })
);


const putHandler = (
  {
    path,
    id,
    body,
    headers,
  }: {
    path: string,
    id?: string,
    body: any
    headers?: any,
  },
): Promise<any> => {
  let url: string = path;
  if (id && id !== '') {
    url += `/${id}`;
  }

  return new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
    try {
      const resp: AxiosResponse = await axios.request({
        method: 'PUT',
        baseURL: BASE_URL,
        url,
        data: body,
        headers: {
          ...getCoreHeader(),
          ...headers,
        },
      });
      resolve(resp);
    } catch (err) {
      handleError(err);
      reject(err);
    }
  });
};

const deleteHandler = (
  {
    path,
    id,
    headers,
  }: {
    path: string,
    id: string,
    headers?: any,
  },
) => {
  let url: string = path;
  if (id && id !== '') {
    url += `/${id}`;
  }

  return new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
    try {
      const resp: AxiosResponse = await axios.request({
        method: 'DELETE',
        baseURL: BASE_URL,
        url,
        headers: {
          ...getCoreHeader(),
          ...headers,
        },
      });
      resolve(resp);
    } catch (err) {
      handleError(err);
      reject(err);
    }
  });
};

const uploadHandler = (
  {
    method,
    path,
    body,
    headers,
    onUploadProgress,
  }: {
    method: any,
    path: string,
    body: any,
    id?: string,
    headers?: any,
    onUploadProgress?: (progressEvent: any) => void
  },
) => new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
  try {
    const resp: AxiosResponse = await axios.request({
      method,
      baseURL: MEDIA_UPLOAD_API_URL,
      url: path,
      data: body,
      headers: {
        ...getCoreHeader(),
        ...headers,
      },
      onUploadProgress,
    });
    resolve(resp);
  } catch (err) {
    handleError(err);
    reject(err);
  }
});

const videoStoreHandler = (
  {
    method,
    path,
    params,
  }: {
    method: any,
    path: string,
    params?: any,
  },
) => new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
  try {
    const resp: AxiosResponse = await axios.request({
      method,
      url: path,
      baseURL: VIDEO_STORE_API_URL,
      headers: {
        ...getCoreHeader(),
      },
      params,
    });
    resolve(resp);
  } catch (err) {
    handleError(err);
    reject(err);
  }
});

const embedHandler = (
  {
    method,
    path,
    params,
  }: {
    method: any,
    path: string,
    params?: any,
  },
) => new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
  try {
    const resp: AxiosResponse = await axios.request({
      method,
      url: path,
      baseURL: EMBED_API_URL,
      headers: {
        ...getCoreHeader(),
      },
      params,
    });
    resolve(resp);
  } catch (err) {
    handleError(err);
    reject(err);
  }
});

const headHandler = async (
  {
    path,
    id,
    params,
    headers,
  }: {
    path: string,
    id?: string,
    params?: any,
    headers?: any,
  },
) => {
  let url: string = path;
  if (id && id !== '') {
    url += `/${id}`;
  }

  return new Promise(async (resolve: HTTPTypeResolve, reject: HTTPTypeReject): Promise<void> => {
    try {
      const resp: AxiosResponse = await axios.request({
        method: 'HEAD',
        baseURL: BASE_URL,
        url,
        params,
        headers: {
          ...getCoreHeader(),
          ...headers,
        },
      });
      resolve(resp);
    } catch (err) {
      reject(err);
    }
  });
};

export default {
  get: getHandler,
  post: postHandler,
  put: putHandler,
  delete: deleteHandler,
  embed: embedHandler,
  upload: uploadHandler,
  videoStore: videoStoreHandler,
  head: headHandler,
};
