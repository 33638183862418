import { DateRange } from '../../../types';

enum Type {
  SET_RECIPE_DISCUSSION_SEARCH_TEXT = 'SET_RECIPE_DISCUSSION_SEARCH_TEXT',
  SET_RECIPE_DISCUSSION_DATE_RANGE = 'SET_RECIPE_DISCUSSION_DATE_RANGE',
  SET_RECIPE_DISCUSSION_FORMATTED_DATE_RANGE = 'SET_RECIPE_DISCUSSION_FORMATTED_DATE_RANGE',
  RESET_RECIPE_DISCUSSION_FILTER = 'RESET_RECIPE_DISCUSSION_FILTER',
}

export type SetSearchKeyword = {
  type: Type.SET_RECIPE_DISCUSSION_SEARCH_TEXT;
  searchKeyword: string
};

export type SetDateRange = {
  type: Type.SET_RECIPE_DISCUSSION_DATE_RANGE;
  dateRange: DateRange
};

export type SetFormattedDateRange = {
  type: Type.SET_RECIPE_DISCUSSION_FORMATTED_DATE_RANGE;
  dateRange: string,
};

export type ResetFilter = {
  type: Type.RESET_RECIPE_DISCUSSION_FILTER,
};

export { Type };
