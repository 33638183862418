import React, {
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import Icon from '../Icon';
import { getUserCookie, clearUserCookie, clearAccessTokenCookie } from '../../utils/cookie';
import { User } from '../../types/user';
import { navMenu } from './config';
import NavSection from './NavSection';
import './index.scss';

type AvatarProps = { className: string, picture: string | undefined };

type AppLayoutProps = {
  children: ReactNode,
  unRepliedDiscussionCount?: number,
  className?: string,
}

const Avatar = ({ className, picture }: AvatarProps) => (
  <div
    className={cls('u-size40 u-borderRadius50Percent u-backgroundColorGreyscale10 u-backgroundNoRepeat u-backgroundSizeCover', className)}
    style={{
      backgroundImage: `url('${picture}')`,
    }}
  />
);

const AppLayout = ({
  unRepliedDiscussionCount,
  children,
  className,
}: AppLayoutProps) => {
  const history = useHistory();
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setUser(getUserCookie());
  }, []);

  const handleLogout = (): void => {
    clearUserCookie();
    clearAccessTokenCookie();
    history.push('/login');
  };

  const toggleDrawer = (): void => {
    if (!showDrawer) {
      document.body.classList.add('u-sm-overflowHidden');
    } else {
      document.body.classList.remove('u-sm-overflowHidden');
    }

    setShowDrawer(!showDrawer);
  };

  const hideDrawer = (): void => {
    document.body.classList.remove('u-sm-overflowHidden');
    setShowDrawer(false);
  };

  const renderAuth = (): ReactNode => {
    if (!user) {
      return null;
    }

    return (
      <div className="AppLayout-auth u-paddingRight16">
        <div className="u-floatRight u-height60">
          <Avatar
            className="u-inlineBlock u-marginVertical10"
            picture={user.picture}
          />
          <div className="u-inlineBlock u-marginVertical10 u-marginLeft12 u-verticalAlignTop u-height40">
            <div className="u-fontSize16 u-fontMedium u-lineHeight40 u-height40">
              <span className="u-colorBlack">{user.name}</span>
              <span
                className="u-marginLeft32 u-fontSize14 u-colorPeach100 u-textDecorationUnderline u-cursorPointer"
                onClick={handleLogout}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDrawerBrand = (): ReactNode => (
    <div className="AppLayout-brand u-paddingHorizontal36 u-paddingTop24">
      <div className="u-textAlignLeft u-paddingBottom24 u-borderBottom1 u-borderColorGreyscale20">
        <div
          className="u-width126 u-height46 logo"
        />
        <div className="u-colorGreyscale40 u-fontMedium u-marginTop8">DASHBOARD</div>
      </div>
    </div>
  );

  const renderDrawerNavMenu = (): ReactNode => (
    <div className="AppLayout-navMenu u-paddingTop36 u-paddingBottom100">
      {
        navMenu.map((item) => {
          const key = item.sectionName;

          return (
            <NavSection
              className="u-marginBottom16"
              key={key}
              navMenu={item}
              unRepliedDiscussionCount={unRepliedDiscussionCount}
              onHideDrawer={hideDrawer}
            />
          );
        })
      }
    </div>
  );

  const renderDrawerAuth = (): ReactNode => {
    if (!user) {
      return null;
    }

    return (
      <div
        className="AppLayout-drawerAuth u-paddingHorizontal40 u-paddingVertical26 u-height92 u-fullWidth u-fixed u-bottom0 u-left0 u-backgroundColorWhite"
      >
        <div>
          <Avatar
            className="u-inlineBlock"
            picture={user.picture}
          />
          <div className="u-inlineBlock u-verticalAlignTop u-height36 u-marginVertical2 u-marginLeft12">
            <div className="u-fontSize16 u-lineHeight18 u-fontMedium u-colorBlack">{user.name}</div>
            <span
              className="u-fontSize14 u-lineHeight18 u-colorPeach100 u-textDecorationUnderline u-cursorPointer"
              onClick={handleLogout}
            >
              Logout
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cls('AppLayout', className)}>
      <header className="AppLayout-appBar u-relative u-backgroundColorWhite u-height60 u-fontRubik">
        <Icon
          icon="drawer"
          className="u-absolute u-left0 u-top0 u-marginLeft16 u-marginVertical18"
          size={24}
          color="dark"
          onClick={toggleDrawer}
          cursorPointer
        />
        {renderAuth()}
      </header>
      <div className={cls('AppLayout-drawer', { 'is-drawer-open': showDrawer })}>
        <div
          className="AppLayout-drawerOverlay u-fullSize u-fixed u-top0 u-left0 u-zIndex7"
          onClick={toggleDrawer}
        />
        <div
          className="AppLayout-drawerMenu u-backgroundColorWhite u-fullHeight u-top0 u-left0 u-paddingBottom92 u-zIndex7"
        >
          <div className="u-overflowYScroll u-absolute u-fullSize u-top0 u-left0">
            {renderDrawerBrand()}
            {renderDrawerNavMenu()}
          </div>
          {renderDrawerAuth()}
        </div>
      </div>
      <main className="AppLayout-content">
        {children}
      </main>
    </div>
  );
};

const mapStateToProps: any = (state) => {
  const { unRepliedDiscussionCount } = state.appReducer;

  return {
    unRepliedDiscussionCount,
  };
};

// PropTypes somehow always throw a warning in a
// `withRouter` wrapped component
export default connect(mapStateToProps)(AppLayout);
