enum Type {
  SET_UNREPLIED_DISCUSSION_COUNT = 'SET_UNREPLIED_DISCUSSION_COUNT',
}

export type SetTotalUnRepliedDiscussion = {
  type: Type.SET_UNREPLIED_DISCUSSION_COUNT;
  totalUnReplied: number,
};

export { Type };
