import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import icons from './Svg';
import './index.scss';

type Props = {
  icon: 'calendar' | 'camera' | 'chevronRight'
      | 'close' | 'deleteRound'| 'image'
      | 'tag' | 'pencil'| 'plusCircle' | 'tips' | 'trashCircle'
      | 'trashDelete' | 'trash' | 'upload'
      | 'warning' | 'plus' | 'send' | 'link'
      | 'product' | 'search' | 'video' | 'picture' | 'arrow'
      | 'bold' | 'drawer' | 'italic' | 'quote' | 'tickCircle'
      | 'warningCircle' | 'outlineArticle' | 'portion' | 'file'
      | 'setting' | 'collapseChevron',
  /**
   * if size provided, height & width props will be avoided
   */
  size?: number,
  width?: number,
  height?: number,
  color?: 'dark' | 'robinegg' | 'robinegg80' | 'grey-blue'
        | 'grey30' | 'grey50' | 'peach' | 'white',
  className?: string,
  cursorPointer?: boolean,
  onClick?: () => void,
}

const Icon: FunctionComponent<Props> = ({
  icon,
  size = 24,
  width,
  height,
  color,
  className,
  cursorPointer,
  onClick,
}) => {
  const baseClasses: { [key: string]: string } = {
    iconDark: 'icon--dark',
    iconRobinegg: 'icon--robinegg',
    iconRobinegg80: 'icon--robinegg80',
    iconGreyBlue: 'icon--greyBlue',
    iconGrey30: 'icon--grey30',
    iconGrey50: 'icon--grey50',
    iconPeach: 'icon--peach',
    iconWhite: 'icon--white',
    cursorPointer: 'u-cursorPointer',
  };

  const propsClass: { [key: string]: boolean } = {
    [baseClasses.iconDark]: color === 'dark',
    [baseClasses.iconRobinegg]: color === 'robinegg',
    [baseClasses.iconRobinegg80]: color === 'robinegg80',
    [baseClasses.iconGreyBlue]: color === 'grey-blue',
    [baseClasses.iconGrey30]: color === 'grey30',
    [baseClasses.iconGrey50]: color === 'grey50',
    [baseClasses.iconPeach]: color === 'peach',
    [baseClasses.iconWhite]: color === 'white',
    [baseClasses.cursorPointer]: !!cursorPointer,
  };

  const SVGIcon: React.ElementType | undefined = (icons && icons[icon]) || undefined;

  if (!SVGIcon) {
    return null;
  }

  let sizeWidth: number;
  let sizeHeight: number;

  if (width && height) {
    sizeWidth = width;
    sizeHeight = height;
  } else {
    sizeWidth = size;
    sizeHeight = size;
  }

  return (
    <SVGIcon
      onClick={onClick}
      className={cls('icon', propsClass, className)}
      width={sizeWidth}
      height={sizeHeight}
    />
  );
};

export default Icon;
