import { lazy } from 'react';
import AppLayout from '../components/AppLayout';
import AppLayoutContributor from '../components/AppLayoutContributor';
import { LayoutRoute, ScreenRoute } from './config';

const LoginScreen = lazy(() => import('../screens/Login'));
const ArticleManagementScreen = lazy(() => import('../screens/ArticleManagement'));
const ArticleDetailScreen = lazy(() => import('../screens/ArticleDetail'));
const ArticleCategoryScreen = lazy(() => import('../screens/ArticleCategory'));
const CampaignManagementScreen = lazy(() => import('../screens/CampaignManagement'));
const OfficialManagementScreen = lazy(() => import('../screens/OfficialManagement'));
const GalleryManagementScreen = lazy(() => import('../screens/GalleryManagement'));
const HomepageManagementScreen = lazy(() => import('../screens/HomepageManagement'));
const ProductManagementScreen = lazy(() => import('../screens/ProductManagement'));
const ProductDetailScreen = lazy(() => import('../screens/ProductDetail'));
const PushNotificationScreen = lazy(() => import('../screens/PushNotification'));
const RecipeManagementScreen = lazy(() => import('../screens/RecipeManagement'));
const RecipeDetailScreen = lazy(() => import('../screens/RecipeDetail'));
const RecipeDailyMenu = lazy(() => import('../screens/RecipeDailyMenu'));
const RecipeCategoryScreen = lazy(() => import('../screens/RecipeCategory'));
const RecipeCategoryDetailScreen = lazy(() => import('../screens/RecipeCategoryDetail'));
const RecipeDiscussionScreen = lazy(() => import('../screens/RecipeDiscussion'));
const CookbookManagementScreen = lazy(() => import('../screens/CookbookManagement'));
const CookbookDetailScreen = lazy(() => import('../screens/CookbookDetail'));
const TagDetailScreen = lazy(() => import('../screens/TagDetail'));
const MenuManagementScreen = lazy(() => import('../screens/MenuManagement'));
const MyProfile = lazy(() => import('../screens/MyProfile'));
const ContributorArticleManagementScreen = lazy(() => import('../screens/Contributor/ArticleManagement'));
const ContributorArticleDetailScreen = lazy(() => import('../screens/Contributor/ArticleDetail'));
const ContributorLoginScreen = lazy(() => import('../screens/Contributor/Login'));

const mainLayoutRoutes: ScreenRoute[] = [
  {
    name: 'Article Management Screen',
    path: '/articles/:status',
    component: ArticleManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Homapage Management Screen',
    path: '/homepage-management',
    component: HomepageManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Product Management Screen',
    path: '/product-management',
    component: ProductManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Article Category Screen',
    path: '/article-category',
    component: ArticleCategoryScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Gallery Management Screen',
    path: '/gallery-management',
    component: GalleryManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Management Screen',
    path: '/recipes/:status',
    component: RecipeManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Category Screen',
    path: '/recipe-category',
    component: RecipeCategoryScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Daily Menu Screen',
    path: '/recipe-daily-menu',
    component: RecipeDailyMenu,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Discussion Screen',
    path: '/recipe-discussion',
    component: RecipeDiscussionScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Cookbook Management Screen',
    path: '/cookbook-management',
    component: CookbookManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Campaign Management',
    path: '/campaign-management',
    component: CampaignManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Push Notification',
    path: '/push-notification',
    component: PushNotificationScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Official Management',
    path: '/official-management',
    component: OfficialManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Menu Management',
    path: '/menu-management',
    component: MenuManagementScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'My Profile Screen',
    path: '/profile',
    component: MyProfile,
    exact: true,
    protected: true,
  },
];

const layoutLessRoutes: ScreenRoute[] = [
  {
    name: 'Login Screen',
    path: '/login',
    component: LoginScreen,
    exact: false,
    protected: false,
  },
  {
    name: 'Article Detail Screen',
    path: '/article/:id',
    component: ArticleDetailScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Category Detail Screen',
    path: '/recipe-category/:id',
    component: RecipeCategoryDetailScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Recipe Detail Screen',
    path: '/recipe/:id',
    component: RecipeDetailScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Cookbook Detail Screen',
    path: '/cookbook/:id',
    component: CookbookDetailScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Product Detail Screen',
    path: '/product-detail/:id',
    component: ProductDetailScreen,
    exact: true,
    protected: true,
  },
  {
    name: 'Tag Detail',
    path: '/tag/:id',
    component: TagDetailScreen,
    exact: true,
    protected: true,
  },
];

const contributorLayoutRoutes: ScreenRoute[] = [
  {
    name: 'Contributor Article Management',
    path: '/contributor/articles',
    component: ContributorArticleManagementScreen,
    exact: true,
    protected: true,
  },
];

const contributorLayoutLessRoutes: ScreenRoute[] = [
  {
    name: 'Login Screen',
    path: '/contributor/login',
    component: ContributorLoginScreen,
    exact: true,
    protected: false,
  },
  {
    name: 'Contributor Article Detail',
    path: '/contributor/article/:id',
    component: ContributorArticleDetailScreen,
    exact: true,
    protected: true,
  },
];

export default [
  {
    name: 'main layout routes',
    layout: AppLayout,
    routes: mainLayoutRoutes,
  },
  {
    name: 'layoutless routes',
    layout: null,
    routes: layoutLessRoutes,
  },
] as LayoutRoute[];

export const layoutRoutesContributor = [
  {
    name: 'contributor layout routes',
    layout: AppLayoutContributor,
    routes: contributorLayoutRoutes,
  },
  {
    name: 'contributor layoutless routes',
    layout: null,
    routes: contributorLayoutLessRoutes,
  },
] as LayoutRoute[];
