import React, {
  Component,
  ReactElement,
  ReactNode,
  Suspense,
  FC,
} from 'react';
import { connect } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import layoutRoutes, { layoutRoutesContributor } from '../routes';
import { LayoutRoute, ScreenRoute } from '../routes/config';
import ErrorBoundary from '../components/ErrorBoundary';
import Toast from '../components/Toast';
import ScrollToTop from '../components/ScrollToTop';
import LoadingSpinner from '../components/Loading/Spinner';
import { UserRoleType } from '../enum';
import {
  getAccessTokenCookie,
  getUserCookie,
  clearAccessTokenCookie,
  clearUserCookie,
} from '../utils/cookie';
import {
  fetchUnRepliedDiscussion,
  refreshUnrepliedDiscussionTimer,
} from '../redux/app/action';
import AppSkeleton from './AppSkeleton';
import AppSkeletonContributor from './AppSkeletonContributor';
import '../static/styles/utility.scss';
import '../static/styles/normalize.scss';
import './App.scss';

const LoadingComponent = (): ReactElement => (
  <LoadingSpinner size="large" className="u-flex u-minHeight100vh u-flexJustifyContentCenter u-flexAlignItemsCenter u-fullWidth" />
);

const ProtectedRoute = ({
  isProtected,
  component: ScreenComponent,
  isContributor,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const hasAccessToken = getAccessTokenCookie();

      if (isProtected && !hasAccessToken) {
        if (isContributor) {
          return (
            <Redirect
              to={{
                pathname: '/contributor/login',
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              search: `?redirectTo=${encodeURIComponent(props.location.pathname)}`,
            }}
          />
        );
      }

      if (!isProtected && hasAccessToken) {
        if (isContributor) {
          return (
            <Redirect
              to={{
                pathname: '/contributor/articles',
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/articles/draft',
            }}
          />
        );
      }

      return (
        <Suspense
          fallback={<LoadingComponent />}
        >
          <ScreenComponent {...props} />
        </Suspense>
      );
    }}
  />
);

const ScreenWithLayout: FC<LayoutRoute & { isContributor: boolean }> = ({
  layout: LayoutComponent,
  routes: screenRoutes,
  isContributor,
}) => {
  const renderScreenRoutes = () => (
    <Switch>
      {screenRoutes.map((screenRoute) => {
        const {
          name,
          path,
          exact,
          component: ScreenComponent,
          protected: isProtected,
        } = screenRoute;

        return (
          <ProtectedRoute
            key={name}
            component={ScreenComponent}
            isProtected={isProtected}
            path={path}
            exact={exact}
            isContributor={isContributor}
          />
        );
      })}
    </Switch>
  );

  return (
    <>
      {
        LayoutComponent ? (
          <LayoutComponent>
            {renderScreenRoutes()}
          </LayoutComponent>
        ) : (renderScreenRoutes())
      }
    </>
  );
};

const ScreenSkeleton: FC<any> = ({ isContributor }): ReactElement => (
  <>
    {
      isContributor ? (
        <AppSkeletonContributor />
      ) : (
        <AppSkeleton />
      )
    }
  </>
);

type AppProps = {
  dispatchFetchUnRepliedDiscussion: () => void,
  dispatchRefreshUnrepliedDiscussionTimer: () => void,
}

type AppState = {
  init: boolean,
  initialRoute: UserRoleType,
}

class App extends Component<AppProps, AppState> {
  state: AppState = {
    init: false,
    initialRoute: UserRoleType.admin,
  };

  async componentDidMount() {
    await this.handleInitUserRoleBasedOnPath();
    this.appInit();
  }

  handleInitUserRoleBasedOnPath = (): void => {
    const currentPath = window.location.pathname;

    // not contributor path by pattern
    const regExp = new RegExp(/^((?!\/contributor).)*$/);
    const isNotContributor = regExp.test(currentPath);

    if (isNotContributor) {
      this.setState({ initialRoute: UserRoleType.admin });
    } else {
      this.setState({ initialRoute: UserRoleType.contributor });
    }
  };

  resetUserCookie = (): void => {
    clearAccessTokenCookie();
    clearUserCookie();
  };

  handleInitError = async (): Promise<void> => {
    this.resetUserCookie();
    await this.setState({ init: true });
  };

  appInit = async (): Promise<void> => {
    const { initialRoute } = this.state;

    const {
      dispatchFetchUnRepliedDiscussion,
      dispatchRefreshUnrepliedDiscussionTimer,
    } = this.props;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;

    script.onload = async () => {
      try {
        // check ruote
        const userCookie = getUserCookie();

        // reset cookie when has session and cross role
        if (userCookie?.privilage !== initialRoute) {
          this.resetUserCookie();
        }

        const accessToken = getAccessTokenCookie();
        if (!accessToken) {
          this.handleInitError();
          return;
        }

        dispatchFetchUnRepliedDiscussion();
        dispatchRefreshUnrepliedDiscussionTimer();
        this.setState({ init: true });
      } catch (err) {
        this.handleInitError();
      }
    };

    document.body.appendChild(script);
  };

  renderRoutes = (
    layouBasedRoutes: LayoutRoute[],
    isContributor: boolean,
  ): ReactNode => layouBasedRoutes.map(
    (layoutRoute: LayoutRoute) => {
      const { routes } = layoutRoute;
      const pathList = routes.map((route: ScreenRoute) => route.path);
      return (
        <Route
          key={layoutRoute.name}
          path={pathList}
          exact
        >
          <ScreenWithLayout
            isContributor={isContributor}
            {...layoutRoute}
          />
        </Route>
      );
    },
  )

  renderRoleBasedRoutes = (): ReactNode => {
    const { initialRoute } = this.state;

    if (initialRoute === UserRoleType.admin) {
      return (
        <Switch>
          {this.renderRoutes(layoutRoutes, false)}
          <Route render={() => <Redirect to="/articles/draft" />} />
        </Switch>
      );
    }

    return (
      <Switch>
        {this.renderRoutes(layoutRoutesContributor, true)}
        <Route render={() => <Redirect to="/contributor/articles" />} />
      </Switch>
    );
  }

  render() {
    const { init, initialRoute } = this.state;
    const isContributor = initialRoute === UserRoleType.contributor;

    return (
      <div className="App">
        {
          init ? (
            <ErrorBoundary>
              <BrowserRouter>
                <ScrollToTop />
                {this.renderRoleBasedRoutes()}
              </BrowserRouter>
              <Toast />
            </ErrorBoundary>
          ) : (
            <>
              <ScreenSkeleton isContributor={isContributor} />
              <Toast />
            </>
          )
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchFetchUnRepliedDiscussion: () => dispatch(fetchUnRepliedDiscussion()),
  dispatchRefreshUnrepliedDiscussionTimer: () => dispatch(refreshUnrepliedDiscussionTimer()),
});

export default connect(null, mapDispatchToProps)(App);
