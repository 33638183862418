import React from 'react';
import LoadingSpinner from '../components/Loading/Spinner';
import Logo from '../static/icons/endeus_logo.svg';

export default () => (
  <div className="appSkeleton u-width100vw u-height100vh u-overflowHidden u-flex u-flexJustifyContentCenter u-flexAlignItemsCenter">
    <div className="appSkeleton-appBar u-height60 u-fullWidth u-absolute u-top0 u-left0 u-backgroundColorWhite">
      <div className="appSkeleton-appBarSkeleton">
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
      </div>
      <div className="appSkeleton-appBarDrawerSkeleton" />
    </div>
    <div className="appSkeleton-sideBar u-overflowHidden u-paddingHorizontal36 u-paddingTop24 u-backgroundColorWhite u-width300 u-fullHeight u-absolute u-top0 u-left0">
      <div className="u-textAlignLeft u-paddingBottom24 u-borderBottom1 u-borderColorGreyscale20">
        <img
          className="u-width126 u-height46"
          alt="Endeus Logo"
          src={Logo}
        />
        <div className="u-colorGreyscale40 u-fontMedium u-marginTop8">DASHBOARD</div>
      </div>
      <div className="appSkeleton-sideBarSkeleton">
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
      </div>
    </div>
    <LoadingSpinner
      className="u-marginBottom80"
      size="large"
      randomColor
    />
  </div>
);
