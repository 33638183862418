import React, { useState, ReactNode, BaseSyntheticEvent } from 'react';
import cls from 'classnames';
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon';
import { NavigationMenu } from '../config';

type Props = {
  className?: string,
  navMenu: NavigationMenu,
  unRepliedDiscussionCount?: number,
  onHideDrawer: () => void,
}

const NavSection = ({
  className,
  navMenu,
  unRepliedDiscussionCount,
  onHideDrawer,
}: Props) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(true);

  const determineActivePath = (routePathname: string): boolean => {
    let isActive = false;
    const windowPathname: string = window.location.pathname;
    if (windowPathname.split('/').length > 2) {
      const routeBasePath: string = routePathname.split('/')[1];
      const windowBasePath: string = windowPathname.split('/')[1];
      if (routeBasePath === windowBasePath) {
        isActive = true;
      }
    } else if (routePathname === windowPathname) {
      isActive = true;
    }
    return isActive;
  };

  const handleClickCollapse = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsShowMenu(!isShowMenu);
  };

  const renderMenu = (): ReactNode => {
    if (!isShowMenu) {
      return null;
    }

    const { menu } = navMenu;

    return menu.map((menuItem, idx) => {
      const isLastChild = idx + 1 === menu.length;

      return (
        <NavLink
          key={menuItem.name}
          to={menuItem.path}
          activeClassName="is-active"
          className={cls(
            'u-block u-paddingHorizontal40 u-fontSize16 u-lineHeight30 u-textDecorationNone',
            { 'u-marginBottom8': !isLastChild },
          )}
          onClick={onHideDrawer}
          isActive={() => determineActivePath(menuItem.path)}
        >
          <p className="u-margin0 u-marginLeft32 u-lineClamp1">
            {menuItem.name}
            { menuItem.hasRedDot && unRepliedDiscussionCount && (
              <span className="navSection-itemRedDot" />
            )}
          </p>
        </NavLink>
      );
    });
  };

  return (
    <div className={cls('AppLayout-navSection', className)}>
      <a
        className="u-flex u-flexJustifyContentSpaceBetween u-flexAlignItemsCenter u-paddingHorizontal40 u-fontSize16 u-lineHeight30 u-marginBottom8"
        href={navMenu.path}
        onClick={handleClickCollapse}
      >
        <div className="u-inlineBlock">
          <Icon
            className="u-inlineBlock u-verticalAlignMiddle"
            icon={navMenu.icon as any}
            width={20}
            height={20}
            color="grey30"
          />
          <div className="u-inlineBlock u-verticalAlignMiddle">
            <p className="u-margin0 u-marginLeft12 u-lineClamp1">
              {navMenu.sectionName}
            </p>
          </div>
        </div>
        <Icon
          className={cls('navSection-chevronIcon', { 'is-showMenu': isShowMenu })}
          icon="collapseChevron"
          width={20}
          height={19}
          color="grey30"
        />
      </a>
      {renderMenu()}
    </div>
  );
};

export default NavSection;
