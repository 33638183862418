export enum ContentType {
  recipe = 'recipe',
  story = 'story',
  recipeCategory = 'recipe_category',
  brand = 'brand',
  link = 'link',
  ingredient = 'ingredient',
  video = 'video',
  webpage = 'webpage',
  discussion = 'discussion',
}

export enum SectionType {
  collection = 'collection',
  MOTD = 'motd',
  junior = 'junior',
  link = 'link',
  ads = 'ads',
  shortcut_menu = 'shortcut_menu',
  kulkas = 'kulkas',
  video = 'video',
  webpage = 'webpage',
  discussion = 'discussion',
}
