import Cookie from 'js-cookie';
import type { User } from '../../types';

const USER_COOKIE = '_uc';
const ACCESS_TOKEN = '_at';

export const setUserCookie = (userInfo: User, expires?: number): void => {
  const user: string = JSON.stringify(userInfo);
  Cookie.set(USER_COOKIE, user, { expires });
};

export const getUserCookie = (): User | null => {
  const userCookie: string | undefined = Cookie.get(USER_COOKIE);
  const user: User | null = userCookie ? JSON.parse(userCookie) as User : null;
  return user;
};

export const clearUserCookie = (): void => {
  Cookie.remove(USER_COOKIE);
};

export const setAccessTokenCookie = (accessToken: string): void => {
  const at: string = JSON.stringify(accessToken);
  Cookie.set(ACCESS_TOKEN, at);
};

export const getAccessTokenCookie = (): string | null => {
  const at: string | undefined = Cookie.get(ACCESS_TOKEN);
  if (!at) {
    return null;
  }
  return JSON.parse(at);
};

export const clearAccessTokenCookie = (): void => {
  Cookie.remove(ACCESS_TOKEN);
};
