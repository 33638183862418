import { SetTotalUnRepliedDiscussion, Type } from './types';

export type InitialState = {
  unRepliedDiscussionCount: number
}

type Action = SetTotalUnRepliedDiscussion;

export const initialState: InitialState = {
  unRepliedDiscussionCount: 0,
};

export default (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case Type.SET_UNREPLIED_DISCUSSION_COUNT: {
      return {
        ...state,
        unRepliedDiscussionCount: action.totalUnReplied,
      };
    }
    default:
      return state;
  }
};
