import React from 'react';
import LoadingSpinner from '../components/Loading/Spinner';

export default () => (
  <div className="appSkeleton appSkeleton--contributor u-width100vw u-height100vh u-overflowHidden u-flex u-flexJustifyContentCenter u-flexAlignItemsCenter">
    <div className="appSkeleton-appBar u-height60 u-fullWidth u-absolute u-top0 u-left0 u-backgroundColorWhite">
      <div className="appSkeleton-appBarSkeleton">
        <div className="skeleton" />
        <div className="skeleton" />
        <div className="skeleton" />
      </div>
    </div>
    <LoadingSpinner
      className="u-marginBottom80"
      size="large"
      randomColor
    />
  </div>
);
