import HttpBuilder from '../httpBuilder';
import { getAccessTokenCookie } from '../../utils/cookie';
// TODO: remove this once the /discussion support for x-total-items for unreplied discussion.
const COMMENT_PATH = '/comment';

const fetchComments = (
  {
    num,
    cursor = '',
    keyword = '',
    dateRange = '',
    status = '',
  }: {
    num: number,
    cursor?: string,
    keyword?: string,
    dateRange?: string,
    status?: string,
  },
): Promise<any> => HttpBuilder.get({
  path: COMMENT_PATH,
  params: {
    num: num.toString(),
    cursor,
    keyword,
    date_range: dateRange,
    status,
  },
  headers: {
    Authorization: `Bearer google:${getAccessTokenCookie()}`,
  },
});

const fetchUnrepliedComments = ({ status = 'unreplied' }: { status: string }): Promise<any> => HttpBuilder.head({
  path: COMMENT_PATH,
  params: {
    status,
  },
  headers: {
    Authorization: `Bearer google:${getAccessTokenCookie()}`,
  },
});

const deleteComment = (id: string): Promise<any> => HttpBuilder.delete({
  path: COMMENT_PATH,
  id,
  headers: {
    Authorization: `Bearer google:${getAccessTokenCookie()}`,
  },
});

const submitComment = ({ text, commentId, recipeId }) => HttpBuilder.post({
  path: COMMENT_PATH,
  body: {
    comment: text,
    object_id: recipeId,
    object_type: 'recipe',
    parent_id: commentId,
  },
  headers: {
    Authorization: `Bearer google:${getAccessTokenCookie()}`,
  },
});

export {
  fetchComments,
  submitComment,
  deleteComment,
  fetchUnrepliedComments,
};
