import React, {
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import { getUserCookie, clearUserCookie, clearAccessTokenCookie } from '../../utils/cookie';
import { User } from '../../types/user';
import './index.scss';

type AvatarProps = { className: string, picture: string | undefined };

type AppLayoutProps = {
  children: ReactNode,
  className?: string,
}

const Avatar = ({ className, picture }: AvatarProps) => (
  <div
    className={cls('u-size40 u-borderRadius50Percent u-backgroundColorGreyscale10 u-backgroundNoRepeat u-backgroundSizeCover', className)}
    style={{
      backgroundImage: `url('${picture}')`,
    }}
  />
);

const AppLayout = ({
  children,
  className,
}: AppLayoutProps) => {
  const history = useHistory();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    setUser(getUserCookie());
  }, []);

  const handleLogout = (): void => {
    clearUserCookie();
    clearAccessTokenCookie();
    history.push('/login');
  };

  const renderAuth = (): ReactNode => {
    if (!user) {
      return null;
    }

    return (
      <div className="AppLayout-auth u-paddingRight16">
        <div
          className="u-floatLeft u-width126 u-height46 u-marginVertical8 u-marginLeft32 logo"
        />
        <div className="u-floatRight u-height60">
          <Avatar
            className="u-inlineBlock u-marginVertical10"
            picture={user.picture}
          />
          <div className="u-inlineBlock u-marginVertical10 u-marginLeft12 u-verticalAlignTop u-height40">
            <div className="u-fontSize16 u-fontMedium u-lineHeight40 u-height40">
              <span className="u-colorBlack">{user.name}</span>
              <span
                className="u-marginLeft32 u-fontSize14 u-colorGreyscale20 u-textDecorationUnderline u-cursorPointer u-colorPeach100"
                onClick={handleLogout}
              >
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={cls('AppLayout AppLayout--contributor', className)}>
      <header className="AppLayout-appBar u-relative u-backgroundColorWhite u-height60 u-fontRubik">
        {renderAuth()}
      </header>
      <main className="AppLayout-content">
        {children}
      </main>
    </div>
  );
};

const mapStateToProps: any = (state) => {
  const { unRepliedDiscussionCount } = state.appReducer;

  return {
    unRepliedDiscussionCount,
  };
};

// PropTypes somehow always throw a warning in a
// `withRouter` wrapped component
export default connect(mapStateToProps)(AppLayout);
