type Menu = {
  name: string,
  path: string,
  hasRedDot?: boolean,
};

export type NavigationMenu = {
  sectionName: string,
  path: string,
  icon: string,
  menu: Menu[],
};

export const navMenu: NavigationMenu[] = [
  {
    sectionName: 'Article',
    path: '/#article',
    icon: 'outlineArticle',
    menu: [
      {
        name: 'Management',
        path: '/articles/draft',
      },
      {
        name: 'Category',
        path: '/article-category',
      },
    ],
  },
  {
    sectionName: 'Recipe',
    path: '/#recipe',
    icon: 'portion',
    menu: [
      {
        name: 'Management',
        path: '/recipes/draft',
      },
      {
        name: 'Daily Menu',
        path: '/recipe-daily-menu',
      },
      {
        name: 'Category',
        path: '/recipe-category',
      },
      {
        name: 'Discussion',
        path: '/recipe-discussion',
        hasRedDot: true,
      },
    ],
  },
  {
    sectionName: 'Management',
    path: '/#management',
    icon: 'file',
    menu: [
      {
        name: 'Product',
        path: '/product-management',
      },
      {
        name: 'Cookbook',
        path: '/cookbook-management',
      },
      {
        name: 'HomePage',
        path: '/homepage-management',
      },
      {
        name: 'Gallery',
        path: '/gallery-management',
      },
      {
        name: 'Campaign',
        path: '/campaign-management',
      },
      {
        name: 'Official',
        path: '/official-management',
      },
      {
        name: 'Menu',
        path: '/menu-management',
      },
    ],
  },
  {
    sectionName: 'Others',
    path: '/#others',
    icon: 'setting',
    menu: [
      {
        name: 'Notification',
        path: '/push-notification',
      },
      {
        name: 'Profile',
        path: '/profile',
      },
    ],
  },
];
