import { SetTotalUnRepliedDiscussion, Type } from './types';
import * as Discussion from '../../http/comment';
// TODO change fetchUnrepliedComments to fetchUnrepliedDiscussion(http/discussion)
// if support x-total-items
const UNREPLIED_DISCUSSION_TIME: number = 1000 * 60 * 5;

const setTotalUnRepliedDiscussion = (totalUnReplied: number): SetTotalUnRepliedDiscussion => ({
  type: Type.SET_UNREPLIED_DISCUSSION_COUNT,
  totalUnReplied,
});

export const fetchUnRepliedDiscussion = () => async (dispatch, getState): Promise<void> => {
  const { unRepliedDiscussionCount } = getState().appReducer;

  try {
    const resp = await Discussion.fetchUnrepliedComments({ status: 'unreplied' });
    const totalUnRepliedDiscussion: number = parseInt(resp.headers['x-total-items'], 10);
    if (totalUnRepliedDiscussion !== unRepliedDiscussionCount) {
      await dispatch(setTotalUnRepliedDiscussion(totalUnRepliedDiscussion));
    }
  } catch (err) {
    console.error(err);// eslint-disable-line
  }
};

export const refreshUnrepliedDiscussionTimer = () => async (dispatch): Promise<void> => {
  const refreshUnrepliedDiscussion = () => {
    // @ts-ignore
    clearTimeout(window.UNREPLIED_COMMENT);
    // @ts-ignore
    window.UNREPLIED_COMMENT = setTimeout(async () => {
      await dispatch(fetchUnRepliedDiscussion());
      refreshUnrepliedDiscussion();
    }, UNREPLIED_DISCUSSION_TIME);
  };
  refreshUnrepliedDiscussion();
};
