import {
  ResetFilter,
  SetSearchKeyword,
  SetDateRange,
  SetFormattedDateRange,
  Type,
} from './types';
import { DateRange } from '../../../types';

export type InitialState = {
  searchKeyword: string,
  dateRange: DateRange,
  formattedDateRange: string,
};

type Action =
  SetSearchKeyword
  | SetDateRange
  | SetFormattedDateRange
  | ResetFilter;

const initialState: InitialState = {
  searchKeyword: '',
  dateRange: null,
  formattedDateRange: '',
};

export default (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case Type.SET_RECIPE_DISCUSSION_SEARCH_TEXT: {
      return {
        ...state,
        searchKeyword: action.searchKeyword,
      };
    }
    case Type.SET_RECIPE_DISCUSSION_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.dateRange,
      };
    }
    case Type.SET_RECIPE_DISCUSSION_FORMATTED_DATE_RANGE: {
      return {
        ...state,
        formattedDateRange: action.dateRange,
      };
    }
    case Type.RESET_RECIPE_DISCUSSION_FILTER: {
      return { ...initialState };
    }
    default:
      return state;
  }
};
