import React, { Component } from 'react';
import { ReactComponent as Logo } from '../../static/icons/endeus_logo.svg';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="u-height100vh">
          <div className="u-height50vh u-backgroundColorGreyscale10 u-relative">
            <div className="u-absolute u-bottom0 u-fullWidth u-textAlignCenter u-paddingBottom16">
              <Logo />
              <h1 className="u-fontSize18 u-marginTop8 u-marginBottom0 u-fontMedium u-colorGreyscale40">DASHBOARD</h1>
            </div>
          </div>
          <div className="u-height50vh">
            <p className="u-fontMedium u-fontSize16 u-colorGreyscale60 u-fontSize20 u-textAlignCenter">
              Oops! Something went wrong.
              Please try again & hit &nbsp;
              <span className="u-colorPeach100 u-cursorPointer" onClick={() => window.location.reload()}>refresh</span>
              .
            </p>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
