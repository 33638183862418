import { ReactComponent as arrow } from './arrow.svg';
import { ReactComponent as bold } from './bold.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as camera } from './camera.svg';
import { ReactComponent as chevronRight } from './chevron-right.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as collapseChevron } from './collapse-chevron.svg';
import { ReactComponent as deleteRound } from './delete-round.svg';
import { ReactComponent as drawer } from './drawer.svg';
import { ReactComponent as file } from './file.svg';
import { ReactComponent as image } from './image.svg';
import { ReactComponent as italic } from './italic.svg';
import { ReactComponent as link } from './link.svg';
import { ReactComponent as outlineArticle } from './outline-article.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as picture } from './picture.svg';
import { ReactComponent as plusCircle } from './plus-circle.svg';
import { ReactComponent as plus } from './plus.svg';
import { ReactComponent as portion } from './portion.svg';
import { ReactComponent as product } from './product.svg';
import { ReactComponent as quote } from './quote.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as send } from './send.svg';
import { ReactComponent as setting } from './setting.svg';
import { ReactComponent as tag } from './tag.svg';
import { ReactComponent as tickCircle } from './tick-circle.svg';
import { ReactComponent as tips } from './tips.svg';
import { ReactComponent as trashCircle } from './trash-circle.svg';
import { ReactComponent as trashDelete } from './trash-delete.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as video } from './video.svg';
import { ReactComponent as warningCircle } from './warning-circle.svg';
import { ReactComponent as warning } from './warning.svg';

export default {
  arrow,
  bold,
  calendar,
  camera,
  chevronRight,
  close,
  collapseChevron,
  deleteRound,
  drawer,
  file,
  image,
  italic,
  link,
  outlineArticle,
  pencil,
  picture,
  plusCircle,
  plus,
  portion,
  product,
  quote,
  search,
  send,
  setting,
  tag,
  tickCircle,
  tips,
  trashCircle,
  trashDelete,
  trash,
  upload,
  video,
  warningCircle,
  warning,
};
