import { DiscussionFeed } from '../../../types/discussion';
import { DateRange, Option, Discussion } from '../../../types';

enum Type {
  ATTEMPT_REMOVE_DISCUSSION = 'ATTEMPT_REMOVE_DISCUSSION',
  ATTEMPT_REMOVE_DISCUSSION_IMAGE = 'ATTEMPT_REMOVE_DISCUSSION_IMAGE',
  SET_DISCUSSIONS = 'SET_DISCUSSIONS',
  REMOVE_DISCUSSION = 'REMOVE_DISCUSSION',
  SET_DISCUSSION_LOADING_STATE = 'SET_DISCUSSION_LOADING_STATE',
  SET_DISCUSSION_LOAD_MORE_STATE = 'SET_DISCUSSION_LOAD_MORE_STATE',
  SET_REPLY_FOCUS = 'SET_REPLY_FOCUS',
  SET_REPLY_LOADING_STATE = 'SET_REPLY_LOADING_STATE',
  UPDATE_REPLY_INPUT = 'UPDATE_REPLY_INPUT',
  SET_DISCUSSION_CURSOR = 'SET_DISCUSSION_CURSOR',
  SET_DISCUSSION_DELETE_LOADING = 'SET_DISCUSSION_DELETE_LOADING',
  SET_DISCUSSION_SEARCH_TEXT = 'SET_DISCUSSION_SEARCH_TEXT',
  SET_DISCUSSION_DATE_RANGE = 'SET_DISCUSSION_DATE_RANGE',
  SET_SELECTED_DISCUSSION_VIEW = 'SET_SELECTED_DISCUSSION_VIEW',
}

export type SetDiscussionFeed = {
  type: Type.SET_DISCUSSIONS;
  discussionFeed: DiscussionFeed | null
};

export type RemoveDiscussion = {
  type: Type.REMOVE_DISCUSSION;
  discussionId: string,
};

export type SetLoadingState = {
  type: Type.SET_DISCUSSION_LOADING_STATE;
  isLoading: boolean,
};

export type SetLoadMoreState = {
  type: Type.SET_DISCUSSION_LOAD_MORE_STATE;
  isLoadingMore: boolean,
};

export type SetReplyFocus = {
  type: Type.SET_REPLY_FOCUS;
  id: string | null,
};

export type SetReplyText = {
  type: Type.UPDATE_REPLY_INPUT;
  text: string,
};

export type SetReplyLoadingState = {
  type: Type.SET_REPLY_LOADING_STATE;
  isLoading: boolean,
};

export type AttemptRemoveDiscussion = {
  type: Type.ATTEMPT_REMOVE_DISCUSSION;
  discussionId: string | null,
};

export type AttemptRemoveDiscussionImage = {
  type: Type.ATTEMPT_REMOVE_DISCUSSION_IMAGE;
  discussion: Discussion | null,
};

export type SetDiscussionDeleteLoadingState = {
  type: Type.SET_DISCUSSION_DELETE_LOADING;
  isDeletingDiscussion: boolean,
};

export type SetDiscussionSearchText = {
  type: Type.SET_DISCUSSION_SEARCH_TEXT;
  text: string,
};

export type SetDiscussionDateRange = {
  type: Type.SET_DISCUSSION_DATE_RANGE;
  date: DateRange,
};

export type SetCursor = {
  type: Type.SET_DISCUSSION_CURSOR;
  cursor: string | null,
};

export type SetSelectedDiscussionView = {
  type: Type.SET_SELECTED_DISCUSSION_VIEW;
  selectedDiscussionView: Option,
};

export { Type };
