import { Option } from '../../../types/option';

export const DISCUSSION_VIEW_OPTIONS: Option[] = [
  {
    value: '',
    label: 'View All',
  },
  {
    value: 'unreplied',
    label: 'View Unreplied',
  },
];
