import { combineReducers } from 'redux';
import appReducer from '../app/reducer';
import discussionReducer from '../screens/discussion/reducer';
import recipeDiscussionReducer from '../screens/RecipeDiscussion/reducer';

export default combineReducers({
  appReducer,
  discussionReducer,
  recipeDiscussionReducer,
});
