import { DISCUSSION_VIEW_OPTIONS } from './config';
import { Discussion, DiscussionFeed, Option } from '../../../types';
import {
  SetDiscussionFeed,
  RemoveDiscussion,
  SetLoadingState,
  SetLoadMoreState,
  SetReplyFocus,
  SetReplyText,
  SetReplyLoadingState,
  AttemptRemoveDiscussion,
  AttemptRemoveDiscussionImage,
  SetDiscussionDeleteLoadingState,
  SetDiscussionSearchText,
  SetCursor,
  SetSelectedDiscussionView,
  Type,
} from './types';

type InitialState = {
  tempDeleteDiscussionID: string | null,
  tempDeleteDiscussionImage: Discussion | null,
  discussionFeed: DiscussionFeed | null,
  replyIsSubmitting: boolean,
  replyInputId: string | null,
  replyInputText: string,
  cursor: string | null,
  isLoading: boolean,
  isLoadingMore: boolean,
  isDeletingDiscussion: boolean,
  discussionViewOptions: Option[],
  selectedDiscussionView: Option,
}

type Action =
  SetDiscussionFeed
  | RemoveDiscussion
  | SetLoadingState
  | SetLoadMoreState
  | SetReplyFocus
  | SetReplyText
  | SetReplyLoadingState
  | AttemptRemoveDiscussion
  | AttemptRemoveDiscussionImage
  | SetDiscussionDeleteLoadingState
  | SetDiscussionSearchText
  | SetCursor
  | SetSelectedDiscussionView;

const initialState: InitialState = {
  tempDeleteDiscussionID: null,
  tempDeleteDiscussionImage: null,
  discussionFeed: null,
  isLoading: false,
  isLoadingMore: false,
  replyIsSubmitting: false,
  replyInputId: null,
  replyInputText: '',
  cursor: null,
  isDeletingDiscussion: false,
  discussionViewOptions: DISCUSSION_VIEW_OPTIONS,
  selectedDiscussionView: DISCUSSION_VIEW_OPTIONS[0],
};

export default (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case Type.SET_DISCUSSIONS: {
      return {
        ...state,
        discussionFeed: action.discussionFeed,
      };
    }
    case Type.REMOVE_DISCUSSION: {
      const newDiscussionFeed: any = { ...state.discussionFeed };
      newDiscussionFeed.items = [...newDiscussionFeed.items]
        .filter(item => item.id !== action.discussionId);

      return {
        ...state,
        discussionFeed: newDiscussionFeed,
      };
    }
    case Type.SET_DISCUSSION_LOADING_STATE: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case Type.SET_DISCUSSION_LOAD_MORE_STATE: {
      return {
        ...state,
        isLoadingMore: action.isLoadingMore,
      };
    }
    case Type.SET_REPLY_FOCUS: {
      return {
        ...state,
        replyInputId: action.id,
      };
    }
    case Type.SET_REPLY_LOADING_STATE: {
      return {
        ...state,
        replyIsSubmitting: action.isLoading,
      };
    }
    case Type.UPDATE_REPLY_INPUT: {
      return {
        ...state,
        replyInputText: action.text,
      };
    }
    case Type.ATTEMPT_REMOVE_DISCUSSION: {
      return {
        ...state,
        tempDeleteDiscussionID: action.discussionId,
      };
    }
    case Type.ATTEMPT_REMOVE_DISCUSSION_IMAGE: {
      return {
        ...state,
        tempDeleteDiscussionImage: action.discussion,
      };
    }
    case Type.SET_DISCUSSION_CURSOR: {
      return {
        ...state,
        cursor: action.cursor,
      };
    }
    case Type.SET_DISCUSSION_DELETE_LOADING: {
      return {
        ...state,
        isDeletingDiscussion: action.isDeletingDiscussion,
      };
    }
    case Type.SET_SELECTED_DISCUSSION_VIEW: {
      return {
        ...state,
        selectedDiscussionView: action.selectedDiscussionView,
      };
    }
    default:
      return state;
  }
};
