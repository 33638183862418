import React, { FunctionComponent } from 'react';
import cls from 'classnames';
import './index.scss';

const PREDEFINED_COLORS: string[] = ['peach', 'yellow', 'robinegg', 'green', 'blue', 'purple'];

type Props = {
  className?: string,
  color?: string,
  size?: string,
  randomColor?: boolean,
}

const LoadingSpinner: FunctionComponent<Props> = ({
  className = '',
  color = 'robinegg',
  size = 'small',
  randomColor = false,
}) => {
  const getRandomColor = (): string => PREDEFINED_COLORS[
    Math.floor(Math.random() * PREDEFINED_COLORS.length)
  ];
  const latestColor: string = randomColor ? getRandomColor() : color;

  const baseClasses: { [key: string]: string } = {
    spnPeach: 'spinner--peach',
    spnYellow: 'spinner--yellow',
    spnRobinegg: 'spinner--robinegg',
    spnGreen: 'spinner--green',
    spnBlue: 'spinner--blue',
    spnPurple: 'spinner--purple',
    spnSmall: 'spinner--small',
    spnLarge: 'spinner--large',
  };

  const propsClass: { [key: string]: boolean } = {
    [baseClasses.spnPeach]: latestColor === 'peach',
    [baseClasses.spnYellow]: latestColor === 'yellow',
    [baseClasses.spnRobinegg]: latestColor === 'robinegg',
    [baseClasses.spnGreen]: latestColor === 'green',
    [baseClasses.spnBlue]: latestColor === 'blue',
    [baseClasses.spnPurple]: latestColor === 'purple',
    [baseClasses.spnSmall]: size === 'small',
    [baseClasses.spnLarge]: size === 'large',
  };

  const spinnerClass = cls(
    'spinner',
    propsClass,
    className,
  );

  return (
    <div className={spinnerClass}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;
