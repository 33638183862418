import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import Icon from '../../Icon';
import { DEFAULT_TOAST_TIMEOUT, ToastMessage, ToastType } from '../config';
import './index.scss';

type Props = {
  message: ToastMessage,
  type?: ToastType,
  onClose: () => void,
}

const ToastCard = ({
  message,
  type,
  onClose,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setShow(true);
    }, 100);

    timer = setTimeout(() => {
      setShow(false);
    }, DEFAULT_TOAST_TIMEOUT);

    return () => clearTimeout(timer);
  }, []);

  const classes: string = cls('toast-bar u-fixed u-zIndex9 u-left0 u-right0 u-bottom0 u-marginHorizontalAuto u-marginBottom48 u-paddingLeft56 u-paddingRight40 u-borderRadius4 u-boxShadow1', {
    'u-backgroundColorPeach100': type === ToastType.error,
    'u-backgroundColorGreen100': type === ToastType.success,
    'is-hiding': !show,
    'is-showing': show,
  });

  const toastIndicatorClass = 'u-absolute u-left16 u-top0 u-bottom0 u-marginVerticalAuto';

  return (
    <div className={classes}>
      {
        type === ToastType.success
          ? <Icon className={toastIndicatorClass} icon="tickCircle" color="white" size={24} />
          : <Icon className={toastIndicatorClass} icon="warningCircle" color="white" size={24} />
      }
      <p
        className="u-colorGreyscaleWhite u-marginVertical16 u-fontSize15 u-lineHeight18 u-lineClamp3 u-maxHeight54"
      >
        {message}
      </p>
      <Icon
        className="u-absolute u-right16 u-top0 u-bottom0 u-marginVerticalAuto"
        icon="close"
        color="white"
        size={12}
        cursorPointer
        onClick={onClose}
      />
    </div>
  );
};

export default ToastCard;
